//---------------------------------
//	Modals
//---------------------------------

export function Modals($) {

	// Модальное окно - Заказать звонок
	(function () {
		$('#modalCallback').on('show.bs.modal', function (e) {
			if ($(e.relatedTarget).data('modal-title') !== undefined) {
				$('#modalCallback .modal-title h4').text($(e.relatedTarget).data('modal-title'));
			}
			if ($(e.relatedTarget).data('modal-button') !== undefined) {
				$('#modalCallback .submit span').text($(e.relatedTarget).data('modal-button'));
			}
			if ($(e.relatedTarget).data('modal-form-name') !== undefined) {
				$('#modalCallback input[name=form_name]').val($(e.relatedTarget).data('modal-form-name'));
			}
			if ($(e.relatedTarget).data('modal-ya-goal-name') !== undefined) {
				$('#modalCallback input[name=ya_metrica_goal_name]').val($(e.relatedTarget).data('modal-ya-goal-name'));
			}

			if ($(e.relatedTarget).data('modal-textarea') !== undefined && $(e.relatedTarget).data('modal-textarea') === true) {
				$('#modalCallback .form-group.textarea').removeClass('is-hidden');
			}
		});

		$('#modalCallback').on('hidden.bs.modal', function (e) {
			$('#modalCallback .modal-title h4').text('Заказать звонок');
			$('#modalCallback .modal-title p').text('Наш менеджер свяжется с вами в ближайшее время');
			$('#modalCallback .submit span').text('Отправить');
			$('#modalCallback input[name=form_name]').val('Заказ звонка');
			$('#modalCallback input[name=ya_metrica_goal_name]').val('');
			$('#modalCallback .form-group.textarea').addClass('is-hidden');
		});
	})();

}


