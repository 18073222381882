//---------------------------------
//	Catalog filter
//---------------------------------

import { helpers } from './helpers.js';

export function CatalogFilter($) {
    var url = new URLSearchParams(window.location.search);

    const lazyLoadCatalog = new LazyLoad({
        elements_selector: '.catalog-post-list .lazy'
    });

    // Проверка есть ли фильтрация
    function checkEmptyFilter() {
        if (url.toString() !== '') {
            $('.catalog-filter-wrap .catalog-filter .top-block .right .reset').addClass('is-active');
            $('.filter-list-wrap').addClass('is-has-filters');

            $('.filter-list-wrap .filter-active').addClass('is-hidden');

            // Скрытие не помещающихся выбранных фильтров
            if (window.innerWidth >= 992) {
                let filterWrapWidth = $('.filter-list-wrap').width(),
                    filterItemWidth = 0;

                let itemArr = [];

                setTimeout(() => {
                    $('.filter-list-wrap .filter-active .item').each(function(){
                        if (!$(this).hasClass('more')) {
                            itemArr.push($(this));
                        } else {
                            itemArr.push($(this).find('.item'));
                        }
                    });

                    $('.filter-list-wrap .filter-active .item').remove();
                }, 100);

                setTimeout(() => {
                    itemArr.forEach(item => {
                        $('.filter-list-wrap .filter-active').append(item);
                    });
                }, 200);

                setTimeout(() => {
                    $('.filter-list-wrap .filter-active .item').each(function(){
                        const self = $(this);

                        filterItemWidth += filterItemWidth += self.outerWidth() + 10;

                        if (filterItemWidth > filterWrapWidth) {
                            if ($('.filter-list-wrap .filter-active .item.more').length === 0) {
                                $('.filter-list-wrap .filter-active').append('<div class="item more"><span>...</span><div class="sub-menu"></div></div>');
                            }

                            $('.filter-list-wrap .filter-active .item.more .sub-menu').append(self);
                        }
                    })

                }, 300);
            }
            setTimeout(() => {
                $('.filter-list-wrap .filter-active').removeClass('is-hidden');
            }, 350);

        } else {
            $('.catalog-filter-wrap .catalog-filter .top-block .right .reset').removeClass('is-active');
            $('.filter-list-wrap').removeClass('is-has-filters');
            $('.filter-list-wrap .filter-active').html('');
        }
    }

    // Загрузка отфильтрованных элементов
    function loadProducts(url) {
        let urlData = {};

        if (url.toString() !== '') {
            for (const item of url.entries()) {
                urlData[item[0]] = item[1];
            }
        }

        const ajaxData = {
            action: 'load_catalog_items',
            data: urlData,
            type: 'catalog'
        }

        if ($('.page-locations').length > 0) {
            ajaxData.type = 'map';
        }

        $.ajax({
            type: "POST",
            url: globalParams.ajax_url,
            data: ajaxData,
            beforeSend: function() {
                $('.catalog-post-list').addClass('is-loading');

                if ($('.page-locations').length > 0) {
                    $('.page-locations .map-wrap').addClass('is-loading');
                }

                setTimeout(() => {
                    $('.catalog-post-list .list').html('');
                }, 100);
            },
            success: function(res) {

                $('.catalog-post-list .list').html(res);

                // if (res !== '<p class="not-found">Ничего не найдено :(</p>') {
                //     $('.catalog-filter-wrap .catalog-filter .top-block').addClass('is-finded');
                // } else {
                //     $('.catalog-filter-wrap .catalog-filter .top-block').removeClass('is-finded');
                // } 

                setTimeout(() => {
                    $('.catalog-post-list').removeClass('is-loading');
                    $('.catalog-filter-wrap .catalog-filter .top-block .left .finded span em').text($('.catalog-post-list .list .post-catalog-single').length);
                    $('.catalog-filter-wrap .catalog-filter .top-block .left .finded').addClass('is-active');
                    $('.page-archive .pagination').remove();

                    lazyLoadCatalog.loadAll();
                }, 200);



                // Карта
                if ($('.page-locations').length > 0) {
                    let dataArr = [];

                    $('.page-locations .map-wrap').removeClass('is-loading');

                    if (res !== '<p class="not-found">Ничего не найдено :(</p>') {
                        $('.catalog-post-list .list .post-catalog-single').each(function(){
                            const self = $(this);

                            dataArr.push({
                                title: self.find('.title').text(),
                                link: self.find('.inner').attr('href'),
                                image: self.find('.image img').attr('data-src'),
                                address: self.find('.address').text(),
                                price: self.find('.price .price-val').text(),
                                square: self.find('.price .square').text(),
                                type: self.find('.type').text(),
                            })
                        });

                        helpers.yandexMapInitCatalog(jQuery, $('.page-locations .map-wrap .map')[0], dataArr, 10, false)
                    } else {
                        $('.page-locations .map-wrap .map').append('<p class="not-found">Ничего не найдено :(</p>');
                    }
                }
            }
        });
    }

    // Удаление элементов фильтрации
    function deleteFilterItem(url, key) {
        const el = $('.catalog-filter-wrap .catalog-filter .filter-list > .item *[name=' + key + ']');

        if (el.length > 0) {
            // Select
            if (el[0].type === 'select-one') {
                el[0].selectize.setValue('no');
            }

            // Range
            if (el.data('val-min') !== undefined && el.data('val-max') !== undefined) {
                el.find('.line')[0].noUiSlider.set([el.data('val-min'), el.data('val-max')])

                el.find('.val.min').text(el.data('val-min').toLocaleString('ru-RU'));
                el.find('.val.max').text(el.data('val-max').toLocaleString('ru-RU'));

                url.delete(el.attr('name') + '_from');
                url.delete(el.attr('name') + '_to');

                window.history.pushState(null, null, '?' + url.toString());
                
                loadProducts(url);
            }

            checkEmptyFilter();
        }
    }

    // Удаление одной позиции фильтрации
    $('.filter-list-wrap .filter-active').on('click', '.item .remove', function(){
        deleteFilterItem(url, $(this).parent().data('key'));
        $(this).parent().remove();
    });

    // Первая загрузка страницы
    // if (url.toString() !== '') {
    if (window.location.search !== '') {
        checkEmptyFilter();
        loadProducts(url);
    } else {
        $('.catalog-post-list').removeClass('is-loading');

        if ($('.page-locations').length > 0) {
            checkEmptyFilter();
            loadProducts(url);
        }
    }

    
    // } else {
        // $('.catalog-post-list').removeClass('is-loading');
    // }

    // Сброс фильтра
    $('.catalog-filter-wrap .catalog-filter .top-block .right .reset').click(function(){
        $(this).removeClass('is-active');
        window.location.search = '';
    });

    // Кастомный select
    $('.catalog-filter-wrap .catalog-filter .filter-list .item select').each(function(){
        const self = $(this),
            $select = self.selectize(),
            selectizeEl = $select[0].selectize;

        // Первая загрузка
        if (url.get(self.attr('name')) !== null) {
            selectizeEl.setValue(url.get(self.attr('name')));

            let html = '<div class="item" data-key="' + self.attr('name') + '"><span>' + selectizeEl.getItem(selectizeEl.getValue()).text() + '</span><i class="remove"></i></div>';

            $('.filter-list-wrap .filter-active').append(html);
        }

        // Изменеие 
        $select.on('change', function(e){
            const val = $(this).val();

            if (val !== 'no') {
                url.set(e.target.getAttribute('name'), $(this).val());
            } else {
                url.delete(e.target.getAttribute('name'))
            }

            // Изменение в списке фильтров
            if ($('.filter-list-wrap .filter-active .item[data-key=' + e.target.getAttribute('name') + ']').length === 0) {
                let html = '<div class="item" data-key="' + e.target.getAttribute('name') + '"><span>' + selectizeEl.getItem(val).text() + '</span><i class="remove"></i></div>';
                $('.filter-list-wrap .filter-active').append(html);
            } else {
                $('.filter-list-wrap .filter-active .item[data-key=' + e.target.getAttribute('name') + '] span').text(selectizeEl.getItem(val).text());
            }


            if (window.history.pushState) {
                window.history.pushState(null, null, '?' + url.toString());
            }

            checkEmptyFilter();

            // Загрузка продуктов
            loadProducts(url);
        });
    });
    


    // Открыть
    $('.filter-list-wrap .filter-toggle').click(function(){
        $('body').addClass('is-overflow');
        $('.catalog-filter-wrap').addClass('is-open');
    });

    // Закрыть
    $('.catalog-filter-wrap .catalog-filter .top-block .right .close-filter, .catalog-filter-wrap .catalog-filter .top-block .left .finded span, .catalog-filter-wrap .bg').click(function(){
        $('body').removeClass('is-overflow');
        $('.catalog-filter-wrap').removeClass('is-open');
    });

    // Диапапзоны значений в фильтрах
    $('.catalog-filter-wrap .catalog-filter .filter-list .item .range').each(function(){
        const self = $(this),
            slider = self.find('.line');

        let sliderStart = 0,
            sliderEnd = 0;

        if (url.get(self.attr('name') + '_from') === null && url.get(self.attr('name') + '_to') === null) {
            sliderStart = self.data('val-min');
            sliderEnd = self.data('val-max');
        } else {
            sliderStart = Number(url.get(self.attr('name') + '_from'));
            sliderEnd = Number(url.get(self.attr('name') + '_to'));
        }

        noUiSlider.create(slider[0], {
            start: [sliderStart, sliderEnd],
            step: 10,
            connect: true,
            behaviour: 'drag',
            range: {
                'min': [self.data('val-min')],
                'max': [self.data('val-max')]
            }
        });


        // Первая загрузка
        if (url.get(self.attr('name') + '_from') !== null && url.get(self.attr('name') + '_to') !== null) {
            let html = '<div class="item" data-key="' + self.attr('name') + '"><span>' + self.parent().find('label').html() + ': ' + Number(url.get(self.attr('name') + '_from')).toLocaleString('ru-RU') + '' + ' - ' + Number(url.get(self.attr('name') + '_to')).toLocaleString('ru-RU') + '' + '</span><i class="remove"></i></div>';
            
            $('.filter-list-wrap .filter-active').append(html);
        }

        slider[0].noUiSlider.on('update', function (values, handle) {
            var valFrom = 0,
                valTo = 0;

            if (url.get(self.attr('name') + '_from') === null && url.get(self.attr('name') + '_to') === null) {
                valFrom = Number(values[0]);
                valTo = Number(values[1]);
            } else {
                valFrom = Number(url.get(self.attr('name') + '_from'));
                valTo = Number(url.get(self.attr('name') + '_to'));
            }

            self.find('.val.min').text(valFrom.toLocaleString('ru-RU'));
            self.find('.val.max').text(valTo.toLocaleString('ru-RU'));
        });

        slider[0].noUiSlider.on('change', function (values, handle) {
            url.set(self.attr('name') + '_from', Number(values[0]));
            url.set(self.attr('name') + '_to', Number(values[1]));

            self.find('.val.min').text(Number(values[0]).toLocaleString('ru-RU'));
            self.find('.val.max').text(Number(values[1]).toLocaleString('ru-RU'));


            // Изменение в списке фильтров
            if ($('.filter-list-wrap .filter-active .item[data-key=' + self.attr('name') + ']').length === 0) {
                let html = '<div class="item" data-key="' + self.attr('name') + '"><span>' + self.parent().find('label').html() + ': ' + Number(values[0]).toLocaleString('ru-RU') + ' ₽' + ' - ' + Number(values[1]).toLocaleString('ru-RU') + ' ₽' + '</span><i class="remove"></i></div>';
                $('.filter-list-wrap .filter-active').append(html);
            } else {
                $('.filter-list-wrap .filter-active .item[data-key=' + self.attr('name') + '] span').html(self.parent().find('label').html() + ': ' + Number(values[0]).toLocaleString('ru-RU') + ' ₽' + ' - ' + Number(values[1]).toLocaleString('ru-RU') + ' ₽');
            }

            window.history.pushState(null, null, '?' + url.toString());

            checkEmptyFilter();

            // Загрузка продуктов
            loadProducts(url);
        });

    });
}