//---------------------------------
//	Window Scroll
//---------------------------------

export function WindowScroll($) {

	var lastScrollTop = 0;

	$(window).scroll(function(){
		var scrollTop = $(window).scrollTop();

		// Белый сайдбар на главной
		if ($('.sidebar-main.is-home').length > 0) {

			if ((window.innerHeight - scrollTop) <= $('.sidebar-main .sidebar-inner .bottom .callback-group').position().top) {
				$('.sidebar-main .sidebar-inner .bottom .callback-group .text').addClass('is-black');
			} else {
				$('.sidebar-main .sidebar-inner .bottom .callback-group .text').removeClass('is-black');
			}

			if ((window.innerHeight - scrollTop) <= $('.sidebar-main .sidebar-inner .bottom .phone').position().top) {
				$('.sidebar-main .sidebar-inner .bottom .phone').addClass('is-black');
			} else {
				$('.sidebar-main .sidebar-inner .bottom .phone').removeClass('is-black');
			}

			// Ссылки в меню
			$('.sidebar-main .sidebar-inner .sidebar-menu ul li').each(function(){
				if ((window.innerHeight - scrollTop) <= $(this).position().top) {
					$(this).find('a').addClass('is-gray');
				} else {
					$(this).find('a').removeClass('is-gray');
				}
			});


			// Убрать это когда вернём раздел "О комппании"
			// if (scrollTop >= $('.page-home .section-home.catalog').offset().top) {

			if (scrollTop >= $('.page-home .section-home.buy-steps').offset().top) {
				$('body.home .sidebar-main').removeClass('is-white');
			} else {
				$('body.home .sidebar-main').addClass('is-white');
			}
		}


		// Смена номера секции на главной
		if ($('.wrapper-main .content-main .section-number-home').length > 0) {
			$('.page-home .section-home').each(function(i, item){
				if (scrollTop >= $(this).offset().top - 120) {
					const nextSectionOffset = $($('.page-home .section-home')[i + 1]).offset().top;

					if ($('body.home .wrapper-main .scroll-ico').length > 0) {
						$('body.home .wrapper-main .scroll-ico').attr('data-offset', nextSectionOffset);
					}

					$('.wrapper-main .content-main .section-number-home').text('0' + (i + 1))

					if (i > 0) {
						$('.wrapper-main .content-main .section-number-home').removeClass('is-white');
					} else {
						$('.wrapper-main .content-main .section-number-home').addClass('is-white');
					}
				}
			});
		}

		// Header
		if (window.innerWidth <= 1200) {
			if ($('body').hasClass('home')) {
				if (scrollTop >= $('.page-home .section-home.about-company').offset().top) {
					$('.header').removeClass('is-white');
				} else {
					$('.header').addClass('is-white');
				}

			}
		}


		// Страница - О компании
		if ($('.page-about-company').length > 0) {
			$('.page-about-company .section-page-single > div').each(function(i, item){
				if ($('.page-about-company .section-page-single > div')[i + 1] !== undefined) {
					if (scrollTop >= $(this).offset().top) {
						const nextSectionOffset = $($('.page-about-company .section-page-single > div')[i + 1]).offset().top;

						$('.wrapper-main .scroll-ico').attr('data-offset', nextSectionOffset);
					}
				}
			});


			if ($('.page-about-company .section-page-single .info-circle').length > 0) {
				if ($('.page-about-company .section-page-single .info-circle').hasClass('items-animation')) {
					if (scrollTop >= $('.page-about-company .section-page-single .info-circle').offset().top - (window.innerHeight - (window.innerHeight / 2))) {
						$('.page-about-company .section-page-single .info-circle').removeClass('items-animation');
					}
				}
			}
		}


		lastScrollTop = scrollTop;
	});

}
