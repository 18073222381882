//---------------------------------
//	Common
//---------------------------------

import { helpers } from './helpers.js';

export function Common($) {

    // Lazy load image
    const lazyLoadAll = new LazyLoad({
        elements_selector: '.lazy'
    });


    // WOW js
    new WOW().init();
    

    // Куки
    // console.log($.cookie('_ga'))

    // Переход по якорям
    $('.scroll-to').click(function(e){
        const el = $(this).attr('href');

        if (window.location.href === globalParams.site_url + '/') {
            e.preventDefault();

            if (document.querySelector(el) !== null) {
                $('html, body').animate({
                    scrollTop: $(el).offset().top
                }, 500);
            }
        } else {
            window.location.href = globalParams.site_url + '/' + el;
        }
        
        return false;
    });

    // Переход по якорям при клице на иконку скролла
    $('.wrapper-main .scroll-ico').click(function(){
        const offset = $(this).attr('data-offset');

        $('html, body').animate({
            scrollTop: offset
        }, 500);
    });


    // Цвет сайдбар
    if ($('body.home .sidebar-main').length > 0) {

        // Убрать это когда вернём раздел "О комппании"
        if ($(window).scrollTop() >= $('.page-home .section-home.catalog').offset().top) {
        // if ($(window).scrollTop() >= $('.page-home .section-home.about-company').offset().top) {
            $('body.home .sidebar-main').removeClass('is-white');
        }
    }

    // Цвет header
    if ($('body.home .header').length > 0) {

        // Убрать это когда вернём раздел "О комппании"
        // if ($(window).scrollTop() <= $('.page-home .section-home.about-company').offset().top) {
        if ($(window).scrollTop() <= $('.page-home .section-home.catalog').offset().top) {
            $('header').addClass('is-white');
        } else {
            $('header').removeClass('is-white');
        }
    }


    // Yandex карта - Главная страница
    if ($('.page-home .section-home.contacts .map-wrap .map').length > 0) {
        helpers.yandexMapInit($('.page-home .section-home.contacts .map-wrap .map')[0], $('.page-home .section-home.contacts .map-wrap .map').data('map-marker'), $('.page-home .section-home.contacts .map-wrap .map').data('map-zoom'));
    }

    // Yandex карта - Контакты
    if ($('.page-contacts .section-page-single .map-wrap .map').length > 0) {
        helpers.yandexMapInit($('.page-contacts .section-page-single .map-wrap .map')[0], $('.page-contacts .section-page-single .map-wrap .map').data('map-marker'), $('.page-contacts .section-page-single .map-wrap .map').data('map-zoom'));
    }


    // Страница - Расположение
    (function(){
        if ($('.page-locations .content .baron__clipper').length > 0) {
            $('.page-locations .content .baron__clipper').baron({
                scroller: '.page-locations .content .baron__clipper > .scroller',
                track: '.page-locations .content .baron__clipper > .scroller__track',
                bar: '.page-locations .content .baron__clipper > .scroller__track .scroller__bar',
            });
        }
    })();


    // Открытие/закрытие сайдбара в мобильной версии
    $('.sidebar-main, .sidebar-mobile .toggle, .sidedar-overlay, .header .toggle-menu').click(function(){
        if (window.innerWidth <= 1200) {
            if (!$('.sidebar-mobile').hasClass('is-open')) {
                $('body').addClass('is-overflow');
                $('.sidebar-mobile').addClass('is-open');
                $('.sidedar-overlay').addClass('is-active');
                $('.header .toggle-menu').addClass('is-open');
            } else {
                $('body').removeClass('is-overflow');
                $('.sidebar-mobile').removeClass('is-open');
                $('.sidedar-overlay').removeClass('is-active');
                $('.header .toggle-menu').removeClass('is-open');
            }
        }
    });



     // Калькулятор ипотеки
     (function(){
        function declOfNum(n, text_forms) {  
            n = Math.abs(n) % 100; var n1 = n % 10;
            if (n > 10 && n < 20) { return text_forms[2]; }
            if (n1 > 1 && n1 < 5) { return text_forms[1]; }
            if (n1 == 1) { return text_forms[0]; }
            return text_forms[2];
        }

        // Изменение количества лет в фильтре - текст
        (function(){
            $('.form-group.year .form-control').on('keyup', function(){
                this.value = this.value > 99 ? 99 : this.value;

                $(this).parent().find('em').text(declOfNum(this.value, ['год', 'года', 'лет']));
            });
        })();


        function mortgageResult() {
            const buildPrice = Number($('.mortgage-calculator .common-form .form-group.price .form-control').val()),
                initPayment = Number($('.mortgage-calculator .common-form .form-group.init-payment .form-control').val()),
                creditSum = buildPrice - initPayment,
                percent = Number($('.mortgage-calculator .common-form .form-group.percent .form-control').val()),
                month = (12 * Number($('.mortgage-calculator .common-form .form-group.year .form-control').val())),
                initPercent = initPayment * 100 / buildPrice,
                monthPercent = percent / 12 / 100,
                percentPart = creditSum * monthPercent,
                totalBid = Number(Math.pow((1 + monthPercent), month).toFixed(2)),
                monthPay = Number((creditSum * monthPercent * totalBid / (totalBid - 1)).toFixed(0)),
                accruedPercent = (monthPay * month) - creditSum,
                creditTotalPay = (monthPay * month),
                needIncome = (creditTotalPay / month) * 4;


            if (!isNaN(initPercent)) $('.mortgage-calculator .common-form .form-group.init-payment > span').text(initPercent.toFixed(1) + '%');
            if (!isNaN(monthPay)) $('.mortgage-calculator .calculator-result .monthly-paymeny h3').text(monthPay.toLocaleString('ru-RU') + ' ₽');
            if (!isNaN(needIncome)) $('.mortgage-calculator .calculator-result .income').text('от ' + needIncome.toLocaleString('ru-RU') + ' ₽');
            if (!isNaN(creditSum)) $('.mortgage-calculator .calculator-result .credit-sum').text(creditSum.toLocaleString('ru-RU') + ' ₽');
            if (!isNaN(accruedPercent)) $('.mortgage-calculator .calculator-result .accrued-percent').text(accruedPercent.toLocaleString('ru-RU') + ' ₽');
            if (!isNaN(creditTotalPay)) $('.mortgage-calculator .calculator-result .total-payment').text(creditTotalPay.toLocaleString('ru-RU') + ' ₽');
            
        }
        // mortgageResult();

        $('.search-form .calc-mortgage').click(function(){
            var build_price = $('.search-form .mortgage-calculator input[name="build_price"]'),
                init_payment = $('.search-form .mortgage-calculator input[name="init_payment"]'),
                year = $('.search-form .mortgage-calculator input[name="year"]');

            if (build_price.val() === '') {
                build_price.parent().addClass('has-error');
                setTimeout(function() {
                    build_price.parent().removeClass('has-error');
                }, 3000);
            }
            if (init_payment.val() === '') {
                init_payment.parent().addClass('has-error');
                setTimeout(function() {
                    init_payment.parent().removeClass('has-error');
                }, 3000);
            }

            if (build_price.val() !== '' && init_payment.val() !== '' && year.val() !== '') {
                var searchUrl = new URLSearchParams(window.location.search);

                searchUrl.set('build_price', build_price.val());
                searchUrl.set('init_payment', init_payment.val());
                searchUrl.set('period', year.val());

                window.location.href = `${location.origin}/ipoteka?${searchUrl}`
            }
        });

        // Подгрузка новый ипотечных программ
        $('.page-mortgage .mortgage-list .wrap .list-item .more').click(function(){
            $(this).removeClass('is-active');
            $('.page-mortgage .mortgage-list .wrap .list-item .list .item').removeClass('is-hidden');
        });

        // Кнопка в списке "расчитать онлайн"
        $('.calc-online').click(function(){
            var val = $(this).closest('.item').find('.right .prop.percent h4').text();

            $('.mortgage-calculator .common-form .form-group.percent').addClass('is-filled');
            $('.mortgage-calculator .common-form .form-group.percent .form-control').val(Number(val.replace(/%/, '')));
        });

        // Подставление значений из URL запроса 
        (function(){
            var searchUrl = new URLSearchParams(window.location.search);

            if (searchUrl.get('build_price') !== null && searchUrl.get('init_payment') !== null && searchUrl.get('period') !== null) {
                $('.mortgage-calculator .common-form input[name="build_price"]').parent().addClass('is-filled');
                $('.mortgage-calculator .common-form input[name="build_price"]').val(searchUrl.get('build_price'));
                
                $('.mortgage-calculator .common-form input[name="init_payment"]').parent().addClass('is-filled');
                $('.mortgage-calculator .common-form input[name="init_payment"]').val(searchUrl.get('init_payment'));
                
                $('.mortgage-calculator .common-form input[name="year"]').parent().addClass('is-filled');
                $('.mortgage-calculator .common-form input[name="year"]').val(searchUrl.get('period'));
                $('.mortgage-calculator .common-form input[name="year"]').parent().find('em').text(declOfNum(searchUrl.get('period'), ['год', 'года', 'лет']))

                $('.mortgage-calculator .common-form input[name="percent"]').parent().addClass('is-filled');
                $('.mortgage-calculator .common-form input[name="percent"]').val(10.2);

                mortgageResult();

                $('html, body').animate({
                    scrollTop: $('#mortgage-calculator').offset().top
                }, 500);
            }
        })();


        $('.mortgage-calculator').each(function(){
            var self = $(this);

            self.find('.common-form .form-group.price .form-control').on('keyup', function(){
                mortgageResult();
            });
            self.find('.common-form .form-group.percent .form-control').on('keyup', function(){
                mortgageResult();
            });
            self.find('.common-form .form-group.init-payment .form-control').on('keyup', function(){
                mortgageResult();
            });
            self.find('.common-form .form-group.year .form-control').on('keyup', function(){
                mortgageResult();
            });
        });

    })();

    

}
