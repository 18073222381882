//---------------------------------
//	Sliders
//---------------------------------

export function Sliders($) {

	

	// Слайдер на странице одной недвижимости
	(function(){
        const slider = $('.page-single-catalog .section-page-single .images .slider');

		if (slider.length > 0) {
			slider.on('init', function(event, slick){
				slider.parent().find('.count .max').text(slick.slideCount <= 9 ? '0' + slick.slideCount : slick.slideCount);
			});

			slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				lazyLoad: 'progressive',
				adaptiveHeight: true,
				arrows: true,
			});

			slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
				slider.parent().find('.count .current').text((nextSlide + 1) <= 9 ? '0' + (nextSlide + 1) : (nextSlide + 1));
			});
		}
    })();  

}
